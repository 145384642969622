import { reactive } from 'vue'
import axios from 'axios'
import { min, template } from 'lodash'

function useAdmin() {
  const ADMIN_URL = import.meta.env.VITE_APP_ADMIN_URL
  const BASE_URL = import.meta.env.VITE_APP_ADMIN_URL

  const PUSHER_APP_ID = import.meta.env.VITE_APP_PUSHER_APP_ID
  const PUSHER_APP_KEY = import.meta.env.VITE_APP_PUSHER_APP_KEY
  const PUSHER_APP_SECRET = import.meta.env.VITE_APP_PUSHER_APP_SECRET
  const PUSHER_APP_CLUSTER = import.meta.env.VITE_APP_PUSHER_APP_CLUSTER

  const LS_TOKEN_KEY_NAME = 'token'
  const LS_PERMISSION_KEY_NAME = 'userPermissions'
  const LS_USER_KEY_NAME = 'user'

  const is_valid_phone = (phoneNo: string) => {
    return /(^()?(01){1}[23456789]{1}(\d){8})$/i.test(phoneNo)
  }

  const permissionsList = reactive({
    manage_roles: 'manage roles',
    access_control: 'access control',
    users: 'users',
    dashboard: 'dashboard',
    statistics: 'statistics',
    dashboard_management: 'dashboard management',
    order_management: 'order management',
    order: ['order list', 'order create', 'order read', 'order update', 'order delete'],
    unSettledOrder: [
      'unsettled order list',
      'unsettled order create',
      'unsettled order read',
      'unsettled order update',
      'unsettled order delete',
    ],
    refundOrder: [
      'refund order list',
      'refund order create',
      'refund order read',
      'refund order update',
      'refund order delete',
    ],
    complaints: ['complaints list', 'complaints create', 'complaints read', 'complaints update', 'complaints delete'],
    reviews: ['reviews list', 'reviews create', 'reviews read', 'reviews update', 'reviews delete'],
    orderSetting: 'order settings',
    minOrderAmount: ['min order amount list', 'min order amount read'],
    minOrderTime: ['min order time list', 'min order time read'],
    minPaymentTime: ['min payment time list', 'min payment time read'],
    rewardFaq: ['reward faq list', 'reward faq read'],
    rewardStatus: ['reward status list', 'reward status read'],
    redeemSettings: ['redeem settings list', 'redeem settings read'],
    rewardUser: ['reward user list', 'reward user read'],
    service_management: 'service management',
    services: ['services list', 'services create', 'services read', 'services update', 'services delete'],
    category: ['category list', 'category create', 'category read', 'category update', 'category delete'],
    subCategory: [
      'sub-category list',
      'sub-category create',
      'sub-category read',
      'sub-category update',
      'sub-category delete',
    ],
    lineItem: ['line-item list', 'line-item create', 'line-item read', 'line-item update', 'line-item delete'],
    slot: ['slot list', 'slot create', 'slot read', 'slot update', 'slot delete'],
    location: ['location list', 'location create', 'location read', 'location update', 'location delete'],
    partnerManagement: 'partner management',
    partner: ['partner list', 'partner create', 'partner read', 'partner update', 'partner delete'],
    resource: ['resource list', 'resource create', 'resource read', 'resource update', 'resource delete'],
    partnerWallet: [
      'partner wallet list',
      'partner wallet create',
      'partner wallet read',
      'partner wallet update',
      'partner wallet delete',
    ],
    partnerPayment: [
      'partner payment list',
      'partner payment create',
      'partner payment read',
      'partner payment update',
      'partner payment delete',
    ],
    inHouseLeave: [
      'inHouse leave list',
      'inHouse leave create',
      'inHouse leave read',
      'inHouse leave update',
      'inHouse leave delete',
    ],
    inHouseSalary: [
      'inHouse salary list',
      'inHouse salary create',
      'inHouse salary read',
      'inHouse salary update',
      'inHouse salary delete',
    ],
    inHouseNotice: [
      'inHouse notice list',
      'inHouse notice create',
      'inHouse notice read',
      'inHouse notice update',
      'inHouse notice delete',
    ],
    paymentManagement: 'payment management',
    paymentMethod: [
      'payment method list',
      'payment method create',
      'payment method read',
      'payment method update',
      'payment method delete',
    ],
    pricingPlan: [
      'pricing plans list',
      'pricing plans create',
      'pricing plans read',
      'pricing plans update',
      'pricing plans delete',
    ],
    billing: ['billing list', 'billing create', 'billing read', 'billing update', 'billing delete'],
    productManagement: 'product management',
    product: ['product list', 'product create', 'product read', 'product update', 'product delete'],
    productRequest: [
      'product request list',
      'product request create',
      'product request read',
      'product request update',
      'product request delete',
    ],
    marketingManagement: 'marketing management',
    emailMarketing: 'email management',
    campaign: [
      'campaign list',
      'campaign create',
      'campaign read',
      'campaign update',
      'campaign delete',
      'campaign send',
    ],
    template: ['template list', 'template create', 'template read', 'template update', 'template delete'],
    audience: ['audience list', 'audience create', 'audience read', 'audience update', 'audience delete'],
    pushNotification: [
      'push notification list',
      'push notification create',
      'push notification read',
      'push notification update',
      'push notification delete',
    ],
    futureNotification: [
      'future notification list',
      'future notification create',
      'future notification read',
      'future notification update',
      'future notification delete',
    ],
    scheduleNotification: [
      'schedule notification list',
      'schedule notification create',
      'schedule notification read',
      'schedule notification update',
      'schedule notification delete',
    ],
    feed: ['feed list', 'feed create', 'feed read', 'feed update', 'feed delete', 'feed publish'],
    promoCode: ['promo code list', 'promo code create', 'promo code read', 'promo code update', 'promo code delete'],
    hotDeal: ['hot deal list', 'hot deal create', 'hot deal read', 'hot deal update', 'hot deal delete'],
    cardBin: ['card bin list', 'card bin create', 'card bin read', 'card bin update', 'card bin delete'],
    banner: ['banner list', 'banner create', 'banner read', 'banner update', 'banner delete'],
    reportManagement: 'report management',
    report: ['report list', 'report create', 'report read', 'report update', 'report delete'],
    areaReport: [
      'area report list',
      'area report create',
      'area report read',
      'area report update',
      'area report delete',
    ],
    lineItemRevenue: [
      'line item revenue list',
      'line item revenue create',
      'line item revenue read',
      'line item revenue update',
      'line item revenue delete',
    ],
    spOrder: ['sp order list', 'sp order create', 'sp order read', 'sp order update', 'sp order delete'],
    oldReport: ['old report list', 'old report create', 'old report read', 'old report update', 'old report delete'],
    csReport: 'cs report',
    spRoute: ['sp route list', 'sp route create', 'sp route read', 'sp route update', 'sp route delete'],
    otp: ['otp list', 'otp create', 'otp read', 'otp update', 'otp delete'],
    contentManagement: 'content management',
    home: ['home list', 'home create', 'home read', 'home update', 'home delete'],
    about: ['about list', 'about create', 'about read', 'about update', 'about delete'],
    service: ['service list', 'service create', 'service read', 'service update', 'service delete'],
    offer: ['offer list', 'offer create', 'offer read', 'offer update', 'offer delete'],
    ticker: ['ticker list', 'ticker create', 'ticker read', 'ticker update', 'ticker delete'],
    mouPartner: [
      'mou partner list',
      'mou partner create',
      'mou partner read',
      'mou partner update',
      'mou partner delete',
    ],
    futureOfBeauty: [
      'future beauty list',
      'future beauty create',
      'future beauty read',
      'future beauty update',
      'future beauty delete',
    ],
    promotions: ['promotions list', 'promotions create', 'promotions read', 'promotions update', 'promotions delete'],
    employee: ['employee list', 'employee create', 'employee read', 'employee update', 'employee delete'],
    paymentChannel: [
      'payment channel list',
      'payment channel create',
      'payment channel read',
      'payment channel update',
      'payment channel delete',
    ],
    faq: ['faq list', 'faq create', 'faq read', 'faq update', 'faq delete'],
    document: ['document list', 'document create', 'document read', 'document update', 'document delete'],
    preference: 'preference',
  })

  const SECRET_KEY = 'Kali Nagin Ke Jaisi Julfe Teri Kali Kali'
  const SECRET_KEY_SAME_AS_PARTNER_PANEL =
    'Dust in the wind.' /* TO_EDIT_THIS_PLACE, This has to be same as Partner panel gbvar SECRET_KEY*/

  const PUSHER_CREDENTIALS = {
    APP_ID: PUSHER_APP_ID,
    APP_KEY: PUSHER_APP_KEY,
    APP_SECRET: PUSHER_APP_SECRET,
    APP_CLUSTER: PUSHER_APP_CLUSTER,
  }

  return {
    axios,
    LS_TOKEN_KEY_NAME,
    LS_PERMISSION_KEY_NAME,
    LS_USER_KEY_NAME,
    ADMIN_URL,
    BASE_URL,
    is_valid_phone,
    permissionsList,
    SECRET_KEY,
    SECRET_KEY_SAME_AS_PARTNER_PANEL,
    PUSHER_CREDENTIALS,
  }
}

export default useAdmin
