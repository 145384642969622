import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

// import { useAuthStore } from '../stores/authUser'

// const store = useAuthStore()

const routes: Array<RouteRecordRaw> = [
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'landing-page',
        path: '',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'access-control',
        path: 'access-control',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'roles',
            path: 'roles',
            component: () => import('../pages/access-control/Roles.vue'),
          },
          {
            name: 'roles-edit',
            path: 'roles-edit/:id',
            component: () => import('../pages/access-control/RolesEdit.vue'),
          },
          {
            name: 'roles-create',
            path: 'roles-crete',
            component: () => import('../pages/access-control/RoleCreate.vue'),
          },
          {
            name: 'users',
            path: 'users',
            component: () => import('../pages/access-control/Users.vue'),
          },
          {
            name: 'users-create',
            path: 'users-create',
            component: () => import('../pages/access-control/UserCreate.vue'),
          },
          {
            name: 'users-edit',
            path: 'users-edit/:id',
            component: () => import('../pages/access-control/UserEdit.vue'),
          },
        ],
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'statistics',
        path: 'statistics',
        component: () => import('../pages/admin/statistics/Statistics.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      // {
      //   name: 'users',
      //   path: 'users',
      //   component: () => import('../pages/users/UsersPage.vue'),
      // },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
      },
      {
        name: 'orders',
        path: 'orders',
        component: RouteViewComponent,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            name: 'orders-lists',
            path: 'lists',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/pages/order/Orders.vue'),
          },
          {
            name: 'orders-crate',
            path: 'create',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/pages/order/OrderCreate.vue'),
          },
          {
            name: 'orders-details',
            path: 'details/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/OrderShow.vue'),
          },
          {
            name: 'orders-edit',
            path: 'edit/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/OrderEdit.vue'),
          },
          {
            name: 'unsettled-orders',
            path: 'unsettled-orders',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/unsettle-order/UnsettleOrders.vue'),
          },
          {
            name: 'refund-orders',
            path: 'refund-orders',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/refund-order/RefundOrders.vue'),
          },
          {
            name: 'complaints',
            path: 'complaints',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/Complaints.vue'),
          },
          {
            name: 'complaints-create',
            path: 'complaints/create',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/ComplaintCreate.vue'),
          },
          {
            name: 'complaints-show',
            path: 'complaints/show/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/ComplaintShow.vue'),
          },
          {
            name: 'complaints-edit',
            path: 'complaints/edit/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/order/ComplaintEdit.vue'),
          },
          {
            name: 'reviews',
            path: 'reviews',
            meta: {
              requiresAuth: true,
            },

            component: () => import('../pages/reviews/Reviews.vue'),
          },
        ],
      },
      {
        name: 'service',
        path: 'services',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'services',
            path: 'services',
            component: () => import('../pages/services/service/Services.vue'),
          },
          {
            name: 'categories',
            path: 'categories',
            component: () => import('../pages/services/categories/Categories.vue'),
          },
          {
            name: 'categories-create',
            path: 'categories-create',
            component: () => import('../pages/services/categories/CreateCategory.vue'),
          },
          {
            name: 'sub-categories',
            path: 'sub-categories',
            component: () => import('../pages/services/sub-categories/SubCategories.vue'),
          },
          {
            name: 'sub-category-create',
            path: 'sub-category-create',
            component: () => import('../pages/services/sub-categories/CreateSubCategory.vue'),
          },
          {
            name: 'line-items',
            path: 'line-items',
            component: () => import('../pages/services/LineItems/LineItems.vue'),
          },
          {
            name: 'line-items-create',
            path: 'line-items/create',
            component: () => import('../pages/services/LineItems/LineItemsCreate.vue'),
          },
          {
            name: 'line-items-edit',
            path: 'line-items/edit/:id',
            component: () => import('../pages/services/LineItems/LineItemsEdit.vue'),
          },
          {
            name: 'slot-counts',
            path: 'slot-counts',
            component: () => import('../pages/services/slots/Slots.vue'),
          },
          {
            name: 'locations',
            path: 'locations',
            component: () => import('../pages/services/location/Locations.vue'),
          },

          {
            name: 'location-create',
            path: 'location-create',
            component: () => import('../pages/services/location/LocationCreate.vue'),
          },
          {
            name: 'location-edit',
            path: 'location-edit/:id',
            component: () => import('../pages/services/location/LocationEdit.vue'),
          },
        ],
      },
      {
        name: 'partner',
        path: 'partner',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'partners',
            path: 'partners',
            component: () => import('../pages/partner/Partners.vue'),
          },
          {
            name: 'show-partner',
            path: 'show/:id',
            component: () => import('../pages/partner/ShowPartner.vue'),
          },
          {
            name: 'resources',
            path: 'resources',
            component: () => import('../pages/resource/Resources.vue'),
          },
          {
            name: 'show-resources',
            path: 'resource/:id',
            component: () => import('../pages/resource/ShowResources.vue'),
          },
          {
            name: 'partner-wallet',
            path: 'partner-wallet',
            component: () => import('../pages/partner-wallet/PartnerWallet.vue'),
          },
          {
            name: 'show-wallet',
            path: 'show-wallet/:id',
            component: () => import('../pages/partner-wallet/ShowWallet.vue'),
          },
          {
            name: 'partner-payments',
            path: 'partner-payments',
            component: () => import('../pages/partner-payments/PartnerPayments.vue'),
          },
          {
            name: 'partner-payment-edit',
            path: 'partner-payment-edit/:id',
            component: () => import('../pages/partner-payments/EditPayment.vue'),
          },
          {
            name: 'partner-payment-create',
            path: 'partner-payment-create',
            component: () => import('../pages/partner-payments/CreatePayment.vue'),
          },
          {
            name: 'all-payment-data',
            path: 'all-payment-data',
            component: () => import('../pages/partner-payments/AllPaymentData.vue'),
          },
          {
            name: 'create-payment',
            path: 'create-payment',
            component: () => import('../pages/partner-payments/CreatePayment.vue'),
          },
          {
            name: 'payment-edit',
            path: 'payment/edit/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/partner-payments/EditPayment.vue'),
          },
          {
            name: 'in-house-leaves',
            path: 'in-house-leaves',
            component: () => import('../pages/in-house-leaves/InHouseLeave.vue'),
          },
          {
            name: 'in-house-leave-summery',
            path: 'in-house-leave-summery',
            component: () => import('../pages/in-house-leaves/LeaveSummary.vue'),
          },
          {
            name: 'all-leave-applications',
            path: 'all-leave-applications',
            component: () => import('../pages/in-house-leaves/AllLeaveApplications.vue'),
          },
          {
            name: 'in-house-leave-calender',
            path: 'in-house-leave-calender',
            component: () => import('../pages/in-house-leaves/LeaveCalender.vue'),
          },
          {
            name: 'in-house-leave-applications',
            path: 'in-house-leave-applications',
            component: () => import('../pages/in-house-leaves/LeaveApplication.vue'),
          },
          {
            name: 'in-house-leave-applications-edit',
            path: 'in-house-leave-applications-edit/:id',
            component: () => import('../pages/in-house-leaves/EditLeaveApplications.vue'),
          },
          {
            name: 'all-leave-applications',
            path: 'all-leave-applications',
            component: () => import('../pages/in-house-leaves/AllLeaveApplications.vue'),
          },
          {
            name: 'in-house-leave-summary',
            path: 'in-house-leave-summary',
            component: () => import('../pages/in-house-leaves/LeaveSummary.vue'),
          },
          {
            name: 'in-house-salary',
            path: 'in-house-salary',
            component: () => import('../pages/in-house-salary/ShowSalary.vue'),
          },
          {
            name: 'edit-salary',
            path: 'edit-salary',
            component: () => import('../pages/in-house-salary/EditSalary.vue'),
          },
          {
            name: 'sp-wallet-monthly',
            path: 'sp-wallet-monthly/:id',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/in-house-salary/SpWalletMonthly.vue'),
          },
          {
            name: 'disburse-salary',
            path: 'disburse-salary',
            component: () => import('../pages/in-house-salary/DisburseSalary.vue'),
          },
          {
            name: 'in-house-notice',
            path: 'in-house-notice',
            component: () => import('../pages/payments/PaymentsPage.vue'),
          },
        ],
      },
      {
        name: 'payments',
        path: 'payments',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'payment-methods',
            path: 'payment-methods',
            component: () => import('../pages/marketing/payment-method/PaymentMethod.vue'),
          },
          {
            name: 'billing',
            path: 'billing',
            component: () => import('../pages/billing/BillingPage.vue'),
          },
          {
            name: 'pricing-plans',
            path: 'pricing-plans',
            component: () => import('../pages/pricing-plans/PricingPlans.vue'),
          },
        ],
      },
      {
        name: 'products',
        path: 'products',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'all-products',
            path: 'all-products',
            component: () => import('../pages/products/Products.vue'),
          },
          {
            name: 'product-edit',
            path: 'product-edit/:id',
            component: () => import('../pages/products/EditProducts.vue'),
          },
          {
            name: 'product-request',
            path: 'product-request',
            component: () => import('../pages/product-request/ProductRequests.vue'),
          },
          {
            name: 'product-request-create',
            path: 'product-request-create',
            component: () => import('../pages/product-request/CreateProductRequest.vue'),
          },
          {
            name: 'product-request-edit',
            path: 'product-request-edit/:id',
            component: () => import('../pages/product-request/EditProductRequest.vue'),
          },
          {
            name: 'product-request-show',
            path: 'product-request-show/:id',
            component: () => import('../pages/product-request/ShowProductRequest.vue'),
          },
        ],
      },
      {
        name: 'cs-reports',
        path: 'cs-reports',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'sp-route',
            path: 'sp-route',
            component: () => import('../pages/sp-route/SpRoutePage.vue'),
          },
          {
            name: 'otps',
            path: 'otps',
            component: () => import('../pages/cs-reports/otps/Otps.vue'),
          },
        ],
      },
      {
        name: 'marketing',
        path: 'marketing',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'campaigns',
            path: 'campaigns',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/campaigns/CampaignsPage.vue'),
          },
          {
            name: 'templates',
            path: 'templates',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/audiences/audiencesPage.vue'),
          },
          {
            name: 'audiences',
            path: 'audiences',
            meta: {
              requiresAuth: true,
            },
            component: () => import('../pages/audiences/audiencesPage.vue'),
          },
          {
            name: 'push-notification',
            path: 'push-notification',
            component: () => import('../pages/marketing/push-notification/PushNotification.vue'),
          },
          {
            name: 'create-push-notification',
            path: 'create-push-notification',
            component: () => import('../pages/marketing/push-notification/CreateNotification.vue'),
          },
          {
            name: 'edit-push-notification',
            path: 'push-notification/edit/:id',
            component: () => import('../pages/marketing/push-notification/EditNotification.vue'),
          },
          {
            name: 'future-notification',
            path: 'future-notification',
            component: () => import('../pages/marketing/future-notification/FutureNotification.vue'),
          },
          {
            name: 'schedule-notification',
            path: 'schedule-notification',
            component: () => import('../pages/marketing/schedule-notification/ScheduleNotification.vue'),
          },
          {
            name: 'schedule-notification-create',
            path: 'schedule-notification-create',
            component: () => import('../pages/marketing/schedule-notification/SchedularNotificationCreate.vue'),
          },
          {
            name: 'create-schedule-notification',
            path: 'create-schedule-notification',
            component: () => import('../pages/marketing/schedule-notification/CreateNotification.vue'),
          },
          {
            name: 'schedule-notification-content',
            path: 'schedule-notification-content',
            component: () => import('../pages/marketing/schedule-notification/NotificationContent.vue'),
          },
          {
            name: 'schedule-notification-content-create',
            path: 'schedule-notification-content-create',
            component: () => import('../pages/marketing/schedule-notification/NotificationContentCreate.vue'),
          },
          {
            name: 'schedule-notification-content-edit',
            path: 'schedule-notification-content-edit/:id',
            component: () => import('../pages/marketing/schedule-notification/NotificationContentEdit.vue'),
          },
          {
            name: 'create-schedule-notification-content',
            path: 'create-schedule-notification-content',
            component: () => import('../pages/marketing/schedule-notification/CreateContent.vue'),
          },
          {
            name: 'edit-schedule-notification-content',
            path: 'schedule-notification-content/edit/:id',
            component: () => import('../pages/marketing/schedule-notification/EditContent.vue'),
          },
          {
            name: 'feed',
            path: 'feed',
            component: () => import('../pages/marketing/feed/Feed.vue'),
          },
          {
            name: 'feed-create',
            path: 'feed-create',
            component: () => import('../pages/marketing/feed/CreateFeed.vue'),
          },
          {
            name: 'feed-edit',
            path: 'feed-edit/:id',
            component: () => import('../pages/marketing/feed/EditFeed.vue'),
          },
          {
            name: 'promo-codes',
            path: 'promo-codes',
            component: () => import('../pages/promos/PromosPage.vue'),
          },
          {
            name: 'promo-codes-create',
            path: 'promo-codes-create',
            component: () => import('../pages/promos/PromosCodeCreate.vue'),
          },
          {
            name: 'promo-codes-edit',
            path: 'promo-codes-edit/:id',
            component: () => import('../pages/promos/PromosCodeEdit.vue'),
          },
          {
            name: 'hot-deals',
            path: 'hot-deals',
            component: () => import('../pages/marketing/hot-deals/HotDeals.vue'),
          },
          {
            name: 'card-bins',
            path: 'card-bins',
            component: () => import('../pages/marketing/card-bins/CardBINs.vue'),
          },
          {
            name: 'banners',
            path: 'banners',
            component: () => import('../pages/marketing/banners/Banners.vue'),
          },
          {
            name: 'banners-create',
            path: 'banners/create',
            component: () => import('../pages/marketing/banners/BannerCreate.vue'),
          },
          {
            name: 'banners-edit',
            path: 'banners/edit/:id',
            component: () => import('../pages/marketing/banners/BannerEdit.vue'),
          },
        ],
      },
      {
        name: 'reports',
        path: 'reports',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'daily-reports',
            path: 'daily-reports',
            component: () => import('../pages/reports/DailyReport.vue'),
          },
          {
            name: 'area-wise-reports',
            path: 'area-wise-reports',
            component: () => import('../pages/reports/AreaWiseReport.vue'),
          },
          {
            name: 'line-item-revenue',
            path: 'line-item-revenue',
            component: () => import('../pages/reports/LineItemRevenue.vue'),
          },
          {
            name: 'sp-wise-order-count',
            path: 'sp-wise-order-count',
            component: () => import('../pages/sp-wise-order-count/sp-wise-order-count.vue'),
          },
          {
            name: 'old-reports',
            path: 'old-reports',
            component: () => import('../pages/promos/PromosPage.vue'),
          },
        ],
      },
      {
        name: 'faq',
        path: 'faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },
      {
        name: 'order settings',
        path: 'order-settings',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'min-order-amount',
            path: 'min-order-amount',
            component: () => import('../pages/order-settings/min-order-amount/MinOrderAmount.vue'),
          },
          {
            name: 'min-order-time',
            path: 'min-order-time',
            component: () => import('../pages/order-settings/min-order-time/MinOrderTime.vue'),
          },
          {
            name: 'min-payment-time',
            path: 'min-payment-time',
            component: () => import('../pages/order-settings/min-payment-time/MinPaymentTime.vue'),
          },
          {
            name: 'reward-faq',
            path: 'reward-faq',
            component: () => import('../pages/order-settings/reward-faq/RewardFaq.vue'),
          },
          {
            name: 'reward-status',
            path: 'reward-status',
            component: () => import('../pages/order-settings/reward-status/RewardStatus.vue'),
          },
          {
            name: 'redeem-settings',
            path: 'redeem-settings',
            component: () => import('../pages/order-settings/redeem-settings/RedeemSettings.vue'),
          },
          {
            name: 'reward-user',
            path: 'reward-user',
            component: () => import('../pages/order-settings/reward-user/RewardUser.vue'),
          },
        ],
      },
      {
        name: 'Content Management',
        path: 'content-management',
        meta: {
          requiresAuth: true,
        },
        component: RouteViewComponent,
        children: [
          {
            name: 'content-home',
            path: 'home',
            component: () => import('../pages/content-management/HomePage/HomePage.vue'),
          },
          {
            name: 'content-about',
            path: 'about',
            component: () => import('../pages/content-management/AboutPage/AboutPage.vue'),
          },
          {
            name: 'content-service',
            path: 'service',
            component: () => import('../pages/content-management/Service/ServicePage.vue'),
          },
          {
            name: 'content-offer',
            path: 'offer',
            component: () => import('../pages/content-management/Offer/OfferPage.vue'),
          },
          {
            name: 'ticker',
            path: 'ticker',
            component: () => import('../pages/content-management/ticker/Ticker.vue'),
          },
          {
            name: 'ticker-create',
            path: 'ticker-create',
            component: () => import('../pages/content-management/ticker/CreateTicker.vue'),
          },
          {
            name: 'ticker-edit',
            path: 'ticker-edit/:id',
            component: () => import('../pages/content-management/ticker/EditTicker.vue'),
          },
          {
            name: 'offer',
            path: 'offer',
            component: () => import('../pages/content-management/ticker/Ticker.vue'),
          },
          {
            name: 'ticker',
            path: 'ticker',
            component: () => import('../pages/content-management/ticker/Ticker.vue'),
          },
          {
            name: 'mou-partners',
            path: 'mou-partners',
            component: () => import('../pages/content-management/mou-partners/MouPartners.vue'),
          },
          {
            name: 'promotions',
            path: 'promotions',
            component: () => import('../pages/content-management/promotions/Promotions.vue'),
          },
          {
            name: 'promotions-create',
            path: 'promotions-create',
            component: () => import('../pages/content-management/promotions/CreatePromotion.vue'),
          },
          {
            name: 'promotions-edit',
            path: 'promotions-edit/:id',
            component: () => import('../pages/content-management/promotions/EditPromotion.vue'),
          },
          {
            name: 'employees',
            path: 'employees',
            component: () => import('../pages/content-management/employees/Employees.vue'),
          },
          {
            name: 'employees-create',
            path: 'employees-create',
            component: () => import('../pages/content-management/employees/CreateEmployees.vue'),
          },
          {
            name: 'employees-edit',
            path: 'employees-edit/:id',
            component: () => import('../pages/content-management/employees/EditEmployees.vue'),
          },
          {
            name: 'payment-channel',
            path: 'payment-channel',
            component: () => import('../pages/content-management/payment-channel/PaymentChannels.vue'),
          },
          {
            name: 'future-of-beauty',
            path: 'future-of-beauty',
            component: () => import('../pages/content-management/future-of-beauty/FutureOfBeauty.vue'),
          },

          {
            name: 'future-of-beauty-create',
            path: 'future-of-beauty-create',
            component: () => import('../pages/content-management/future-of-beauty/CreateFutureOfBeauty.vue'),
          },

          {
            name: 'future-of-beauty-edit',
            path: 'future-of-beauty-edit/:id',
            component: () => import('../pages/content-management/future-of-beauty/EditFutureOfBeauty.vue'),
          },

          {
            name: 'faqs',
            path: 'faqs',
            component: () => import('../pages/content-management/faq/Faqs.vue'),
          },
          {
            name: 'faqs-create',
            path: 'faqs-create',
            component: () => import('../pages/content-management/faq/CreateFaq.vue'),
          },
          {
            name: 'faqs-edit',
            path: 'faqs-edit/:id',
            component: () => import('../pages/content-management/faq/EditFaq.vue'),
          },
        ],
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../pages/auth/SecondLogin.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  if (to.path && to.path === '/login' && token) return next('/dashboard')

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      return next()
    }

    return next('/login')
  }
  next()
})

export default router
